export const CONSTANTS = {
  PM: "Mes anterior",
  PY: "Año anterior",
  CUSTOM: "Año anterior",
  CHIP_FILTER: {
    equals: "es",
    notEquals: "no es",
    set: "no es",
    notSet: "es",
    gt: "mayor que",
    lt: "menor que",
    between: "está entre",
    inDateRange: "está entre",
  },
  PATH_NOT_FOUND: `Lo sentimos, la ruta que intentas acceder no existe`,
  TENANT_NOT_FOUND_ERROR: `Lo sentimos, tu cuenta de usuario no está asociada a ningún tenant. 
  Por favor, contacta con el equipo de soporte para obtener ayuda.`,
};

export const ROLES = {
  OPERATOR: "operator",
  ADMIN: "admin",
  ANALYST: "analyst",
};

export const ROLES_NAME = {
  operator: "Operador",
  admin: "Administrador",
  analyst: "Analista",
};

export const MEASUREMENT_NAMES = {
  store_id: "store",
  sensor_id: "sensor",
  type: "type",
  param_gen1: "param1",
  param_gen2: "param2",
  param_gen3: "param3",
  is_valid: "isValid",
  measurement_date: "measurementDate",
};

export const MEASUREMENT_VALUES = {
  store_id: "Tienda",
  sensor_id: "Sensor",
};

export const INTERCOM_APP_ID = "oq8jh8bv";

import {
  AdminPanelSettings,
  BarChart,
  Cloud,
  People,
} from "@mui/icons-material";

export const MENU_ITEMS = [
  {
    path: "/visits",
    label: "Visitas",
    index: 0,
    plan: "analyst",
    icon: <BarChart fontSize="small" sx={{ color: "var(--blue-gray-4)" }} />,
  },
  {
    path: "/admin/stores",
    label: "Administración",
    index: 1,
    plan: "admin",
    icon: (
      <AdminPanelSettings
        fontSize="small"
        sx={{ color: "var(--blue-gray-4)" }}
      />
    ),
  },
  {
    path: "/admin/users",
    label: "Usuarios",
    index: 2,
    plan: "admin",
    icon: <People fontSize="small" sx={{ color: "var(--blue-gray-4)" }} />,
  },
  {
    path: "/api",
    label: "Api",
    index: 3,
    plan: "admin",
    icon: <Cloud fontSize="small" sx={{ color: "var(--blue-gray-4)" }} />,
  },
];

export const indexByMenu = {
  "/visits": 0,
  "/admin/stores": 1,
  "/admin/users": 2,
  "/api": 3,
};

import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { Logo } from "components/Logo/Logo";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import styles from "./styles";
import { ProfileSection } from "components/Profile/ProfileSection";
import { Drawer } from "@mui/material";
import { Menu } from "./Menu";
import { useContext } from "react";
import { ConfigContext } from "Context/ConfigProvider";

export function WivoAppBar(props) {
  const {
    appState: {
      identity: { accountData },
    },
  } = useContext(ConfigContext);

  const { name } = accountData;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const appBar = (
    <AppBar position="static" sx={styles.appBar}>
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <Box sx={styles.logoSection}>
            <Logo />
            <ProfileSection />
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );

  const drawer = (
    <Drawer variant="permanent" sx={styles.drawer} anchor="left">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          padding: "1.3em 0.5em",
          width: "200px",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{ marginLeft: "0.7em" }}
        >
          <Logo />
        </Box>
        <Menu />
        <ProfileSection />
      </Box>
    </Drawer>
  );

  return isMobile ? appBar : drawer;
}

export const COLUMNS = {
 /*  "SensorsMeasurements.store": {
    key: "SensorsMeasurements.store",
    label: "Tienda",
    active: true,
    type: "dimension",
  }, */
  "SensorsStores.name": {
    key: "SensorsStores.name",
    keys: ["SensorsStores.name", "SensorsMeasurements.store"],
    label: "Tienda",
    active: true,
    type: "dimension",
  },
  "SensorsMeasurements.visits": {
    key: "SensorsMeasurements.visits",
    label: "Visitas",
    active: true,
    type: "measure",
  },
  "SensorsMeasurements.previousVisits": {
    key: "SensorsMeasurements.previousVisits",
    label: "Visitas anterior",
    active: true,
    type: "number",
    columnType: "number",
    description: "Visitas del periodo anterior",
  },
  "SensorsMeasurements.visitsVariation": {
    key: "SensorsMeasurements.visitsVariation",
    label: "Var. de visitas",
    active: true,
    type: "number",
    columnType: "percent",
    description: "Variación de visitas respecto al periodo anterior",
  },
  "SensorsMeasurements.externalFlow": {
    key: "SensorsMeasurements.externalFlow",
    label: "Flujo externo",
    active: false,
    type: "measure",
  },
  "SensorsMeasurements.previousExternalFlow": {
    key: "SensorsMeasurements.previousExternalFlow",
    label: "Flujo externo anterior",
    active: false,
    type: "number",
    columnType: "number",
    description: "Flujo externo del periodo anterior",
  },
  "SensorsMeasurements.externalFlowVariation": {
    key: "SensorsMeasurements.externalFlowVariation",
    label: "Var. Flujo externo",
    active: false,
    type: "number",
    columnType: "percent",
    description: "Variación del flujo externo respecto al periodo anterior",
  },
  //Estas columnas son dinámicas por tenant
  /*  "SensorsMeasurements.param1": {
    key: "SensorsMeasurements.param1",
    label: "Parametro genérico 1",
    active: false,
    type: "dimension",
  },
  "SensorsMeasurements.param2": {
    key: "SensorsMeasurements.param2",
    label: "Parametro genérico 2",
    active: false,
    type: "dimension",
  },
  "SensorsMeasurements.param3": {
    key: "SensorsMeasurements.param3",
    label: "Parametro genérico 3",
    active: false,
    type: "dimension",
  }, */
};

export const HIDDECOLUMNS = {
  "SensorsMeasurements.store": false,
};

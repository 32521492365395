import React, { useContext, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import cubejs, { HttpTransport } from "@cubejs-client/core";
import AppLoading from "components/AppLoading/AppLoading";
import { getUserData } from "utils/utils";
import { CubeProvider } from "@cubejs-client/react";
import { ConfigContext } from "Context/ConfigProvider";
import { WivoAppBar } from "components/AppBar/WivoAppBar";
import NotFound from "components/NotFoundPage/NotFoundPage";
import { ThemeProvider, createTheme } from "@mui/material";
import TenantNotFound from "components/TenantNotFound/TenantNotFound";
import { INTERCOM_APP_ID, ROLES } from "utils/constants";
import { useLocation, useHistory } from "react-router-dom";
import { useRef } from "react";

const tenantQuery = () => ({
  dimensions: [
    "SensorsTenants.tenant",
    "SensorsTenants.name",
    "SensorsTenants.param1",
    "SensorsTenants.param2",
    "SensorsTenants.param3",
    "SensorsTenants.token",
    "SensorsTenants.active",
  ],
});

const ADMIN_PATHS = {
  "/admin/stores": true,
  "/admin/users": true,
  "/api": true,
};
export const uitheme = createTheme({
  palette: {
    //primary: { 500: "rgb(242, 44, 54)" },
  },
  fontFamily: "IBM Plex Sans",
});

const withCubejsApi = (Component) => {
  return (props) => {
    const location = useLocation();
    const { pathname } = location;
    const { isLoading, isAuthenticated, user, getAccessTokenSilently } =
      useAuth0();
    const { setAppState } = useContext(ConfigContext);
    const [cubejsApi, setCubejsApi] = useState(null);
    const [userData, setUserData] = useState(null);
    const activeUser = useRef(null);

    const isUserRoute = pathname.startsWith("/u/");
    const cleanPath = isUserRoute
      ? `/${pathname.split("/u/")[1].split("/", 2)[1]}`
      : pathname;

    activeUser.current = window.location.pathname
      .split(cleanPath)[0]
      .split("/u/")[1];

    useEffect(() => {
      if (process.env.NODE_ENV !== "development") {
        window.intercomSettings = {
          api_base: "https://api-iam.intercom.io",
          app_id: INTERCOM_APP_ID,
        };
      }
    }, []);

    useEffect(() => {
      if (userData && process.env.NODE_ENV !== "development") {
        const { user_id, name, email, user_created_at } = userData;
        window.Intercom("update", {
          user_id,
          name,
          email,
          created_at: user_created_at,
          sensores_app: true,
        });
      }
    }, [userData]);

    useEffect(() => {
      const createCubejsApi = async () => {
        if (isAuthenticated) {
          const accessToken = await getAccessTokenSilently();
          const userData = await getUserData(
            user,
            accessToken,
            activeUser.current
          );
          const { tenant } = userData;

          const api = cubejs({
            transport: new HttpTransport({
              authorization: `Bearer ${accessToken}`,
              apiUrl: `${process.env.REACT_APP_CUBEJS_URL}`,
              headers: {
                tenant,
                schema: "SensorsMeasurements",
                ignoretenant: false,
              },
            }),
          });
          setUserData(userData);
          setCubejsApi(api);
          setAppState((prevState) => ({
            ...prevState,
            identity: {
              ...prevState.identity,
              accessToken,
              userData,
            },
            config: {
              ...prevState.config,
              cubejsApi: api,
            },
          }));
          if (!tenant) return;
          const getTenantApi = cubejs({
            transport: new HttpTransport({
              authorization: `Bearer ${accessToken}`,
              apiUrl: `${process.env.REACT_APP_CUBEJS_URL}`,
              headers: {
                tenant,
                schema: "SensorsTenants",
                ignoretenant: false,
              },
            }),
          });

          let accountData = {};
          getTenantApi.load(tenantQuery()).then((resp) => {
            const data = resp.tablePivot()[0];
            Object.keys(resp.tablePivot()[0] || {}).forEach((key) => {
              const formattedKey = key.split("SensorsTenants.")[1];
              if (formattedKey === "token")
                accountData[formattedKey] = data[key]
                  ? data[key]
                  : "No disponible";
              else if (data[key]) accountData[formattedKey] = data[key];
            });
            setAppState((prevState) => ({
              ...prevState,
              identity: {
                ...prevState.identity,
                accountData,
              },
            }));
          });

          const getTenantsApi = cubejs({
            transport: new HttpTransport({
              authorization: `Bearer ${accessToken}`,
              apiUrl: `${process.env.REACT_APP_CUBEJS_URL}`,
              headers: {
                tenant,
                schema: "SensorsTenants",
                ignoretenant: true,
              },
            }),
          });

          getTenantsApi
            .load({
              ...tenantQuery(),
              filters: [
                {
                  member: "SensorsTenants.tenant",
                  operator: "equals",
                  values: userData.sensors_tenant,
                },
              ],
            })
            .then((resp) => {
              setAppState((prevState) => ({
                ...prevState,
                identity: {
                  ...prevState.identity,
                  userData: {
                    ...userData,
                    accountsList:
                      resp?.tablePivot().map((data) => {
                        const newObject = {};
                        Object.keys(data || {}).forEach((key) => {
                          const formattedKey = key.split("SensorsTenants.")[1];
                          if (data[key]) newObject[formattedKey] = data[key];
                        });
                        return newObject;
                      }) || [],
                  },
                },
              }));
            });

          //setUserData(userData);
          //setCubejsApi(api);
        }
      };
      createCubejsApi();
    }, [
      isLoading,
      isAuthenticated,
      user,
      getAccessTokenSilently,
      activeUser.current,
    ]);

    if (isLoading || !cubejsApi) {
      return <AppLoading text="Cargando..." />;
    }

    if (!userData?.tenant)
      return (
        <ThemeProvider theme={uitheme}>
          <WivoAppBar />
          <div
            style={{
              height: "80vh",
              display: "flex",
              alignContent: "center",
            }}
          >
            <TenantNotFound />
          </div>
        </ThemeProvider>
      );

    if (userData?.sensors_role === ROLES.ANALYST && ADMIN_PATHS[pathname]) {
      return (
        <ThemeProvider theme={uitheme}>
          <WivoAppBar />
          <div
            style={{
              height: "80vh",
              display: "flex",
              alignContent: "center",
            }}
          >
            <NotFound />
          </div>
        </ThemeProvider>
      );
    }

    return (
      <CubeProvider cubejsApi={cubejsApi}>
        {userData?.tenant ? (
          <Component {...props} />
        ) : (
          <ThemeProvider theme={uitheme}>
            <WivoAppBar />
            <div
              style={{
                height: "80vh",
                display: "flex",
                alignContent: "center",
              }}
            >
              <NotFound />
            </div>
          </ThemeProvider>
        )}
      </CubeProvider>
    );
  };
};

export default withCubejsApi;

import React from "react";

//import { Button } from "@mui/material";

import { CONSTANTS } from "utils/constants";

import styles from "./styles";

const TenantNotFound = () => {
  /*   const handleContact = (e) => {
    e.preventDefault();
    window.Intercom("show");
  }; */

  return (
    <div style={styles.root}>
      <img src="/images/logo.svg" alt="wivo" style={{ height: "10vh" }} />
      <h1 style={{ textAlign: "center", maxWidth: "40%", fontSize: "1.1em" }}>
        {CONSTANTS.TENANT_NOT_FOUND_ERROR}
      </h1>
      {/*   <Button variant="text" onClick={handleContact}>
        {CONSTANTS.SUPPORT}
      </Button> */}
    </div>
  );
};
export default TenantNotFound;

import { dateRanges } from "components/NewCalendar/ranges";
import jwt_decode from "jwt-decode";
import moment from "moment";

const claims = process.env.REACT_APP_CLAIMS;

export function generateId() {
  return Math.random().toString(36).substr(2) + Date.now().toString(36);
}

export const getUserData = async (user, accessToken, activeUser) => {
  const decoded = jwt_decode(accessToken);
  const { sensors_tenant } = decoded[claims];
  return {
    ...user,
    ...decoded[claims],
    tenant: sensors_tenant ? sensors_tenant[activeUser || 0] : undefined,
  };
};

export const getPreviusRange = (timeDimensions, compareTo) => {
  const { dateRange } = timeDimensions[0];
  const isDateRangeString = typeof dateRange === "string";

  let [startDate, endDate] = isDateRangeString
    ? [dateRanges[dateRange].start, dateRanges[dateRange].end]
    : dateRange;

  if (!compareTo) return { startDate, endDate };

  const granularitySubtract = compareTo === "PM" ? "month" : "year";
  const prevStartDate = moment(startDate)
    .subtract(1, granularitySubtract)
    .format("YYYY-MM-DD");
  const prevEndDate = moment(endDate)
    .subtract(1, granularitySubtract)
    .format("YYYY-MM-DD");
  return { startDate, endDate, prevStartDate, prevEndDate };
};

export const handleOnPageChange = (newPage, page, offset, limit) => {
  let newOffset = 0;
  if (newPage > page) {
    newOffset = offset + limit;
  } else if (newPage < page) {
    if (offset >= limit) {
      newOffset = offset - limit;
    } else newOffset = 0;
  } else if (newPage === 0) {
    newOffset = 0;
  }
  return newOffset;
};

export function isValidDate(dateString) {
  const regex = /^\d{4}-\d{2}-\d{2}( \d{2}:\d{2}(:\d{2})?)?$/;
  if (!regex.test(dateString)) return false;

  const date = new Date(dateString);
  const dateNum = date.getTime();
  if (!dateNum && dateNum !== 0) return false;

  let year, month, day, hours, minutes, seconds;
  if (dateString.length <= 10) {
    // Si la cadena de entrada no incluye una hora, usa UTC
    year = date.getUTCFullYear().toString().padStart(4, "0");
    month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
    day = date.getUTCDate().toString().padStart(2, "0");
    hours = date.getUTCHours().toString().padStart(2, "0");
    minutes = date.getUTCMinutes().toString().padStart(2, "0");
    seconds = date.getUTCSeconds().toString().padStart(2, "0");
  } else {
    // Si la cadena de entrada incluye una hora, usa tiempo local
    year = date.getFullYear().toString().padStart(4, "0");
    month = (date.getMonth() + 1).toString().padStart(2, "0");
    day = date.getDate().toString().padStart(2, "0");
    hours = date.getHours().toString().padStart(2, "0");
    minutes = date.getMinutes().toString().padStart(2, "0");
    seconds = date.getSeconds().toString().padStart(2, "0");
  }

  let formattedDateString = `${year}-${month}-${day}`;
  if (dateString.length > 10) {
    formattedDateString += ` ${hours}:${minutes}`;
    if (dateString.length > 16) {
      formattedDateString += `:${seconds}`;
    }
  }

  return formattedDateString === dateString;
}

export function capitalize(str) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export const PLANS_HIERARCHY = {
  operator: { hierarchy: ["operator", "admin", "analyst"] },
  admin: { hierarchy: ["admin", "analyst"] },
  analyst: { hierarchy: ["analyst"] },
};

export const isAvailableForPlan = (userPlan, itemPlan) => {
  const result = PLANS_HIERARCHY[userPlan]?.hierarchy.find(
    (plan) => plan === itemPlan
  );
  return result ? true : false;
};

export const isValidEmail = (email) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

/**
 * Convierte horarios en formato legible a expresiones cron.
 * @param {string} scheduleText - El texto del horario.
 * @returns {Array<string>} - Un arreglo de expresiones cron.
 */

export function scheduleToCron(scheduleText) {
  const daysMap = {
    lunes: "1",
    martes: "2",
    miércoles: "3",
    jueves: "4",
    viernes: "5",
    sábado: "6",
    domingo: "0",
  };

  const parseTime = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return { hours, minutes };
  };

  const processSegment = (segment) => {
    const daysPattern = segment.split(" de ")[0].trim();
    const timeRange = segment.split(" de ")[1].trim();
    const [startTime, endTime] = timeRange.split(" a ");
    const { hours: startHour, minutes: startMinute } = parseTime(startTime);
    const { hours: endHour, minutes: endMinute } = parseTime(endTime);

    let dayRange = daysPattern.split(" a ");
    let cronDays;

    if (dayRange.length === 1) {
      cronDays = daysMap[dayRange[0].toLowerCase()];
    } else {
      const startDay = daysMap[dayRange[0].toLowerCase()];
      const endDay = daysMap[dayRange[1].toLowerCase()];
      cronDays = `${startDay}-${endDay}`;
    }

    // Caso donde el rango de tiempo está completamente dentro de una hora
    if (startHour === endHour) {
      return `${startMinute}-${endMinute - 1} ${startHour} * * ${cronDays}`;
    }

    // Si el rango de minutos cubre toda la hora
    if (startMinute === 0 && endMinute === 0) {
      return `* ${startHour}-${endHour} * * ${cronDays}`;
    }

    let cronExpressions = [];

    // Minutos de la primera hora
    if (startMinute > 0) {
      cronExpressions.push(`${startMinute}-59 ${startHour} * * ${cronDays}`);
    } else {
      cronExpressions.push(`0-59 ${startHour} * * ${cronDays}`);
    }

    // Horas completas entre las horas de inicio y fin
    if (startHour + 1 < endHour) {
      cronExpressions.push(`* ${startHour + 1}-${endHour - 1} * * ${cronDays}`);
    }

    // Minutos de la última hora
    if (endMinute > 0) {
      cronExpressions.push(`0-${endMinute - 1} ${endHour} * * ${cronDays}`);
    } else {
      cronExpressions.push(`0-59 ${endHour} * * ${cronDays}`);
    }

    return cronExpressions.join(", ");
  };

  // Dividir el horario en segmentos y procesar cada uno
  const segments = scheduleText.split(" y ");
  return segments.map((segment) => processSegment(segment)).join(", ");
}

export const daysOfWeek = [
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
  "Domingo",
];
export const hoursOfDay = [
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
];

export function validarHoras(horaInicial, horaFinal) {
  // Convertir las horas a formato 24 horas
  let fechaInicio = new Date("01/01/2022 " + horaInicial);
  let fechaFin = new Date("01/01/2022 " + horaFinal);
  return fechaFin > fechaInicio;
}

export const timezones = [
  "America/Santiago", // Chile
  "America/Lima", // Perú
  "America/Mexico_City", // México - Zona horaria central
  "America/Cancun", // México - Zona horaria del este
  "America/Merida", // México - Zona horaria central
  "America/Monterrey", // México - Zona horaria central
  "America/Matamoros", // México - Zona horaria central
  "America/Mazatlan", // México - Zona horaria del Pacífico
  "America/Chihuahua", // México - Zona horaria del Pacífico
  "America/Ojinaga", // México - Zona horaria del Pacífico
  "America/Hermosillo", // México - Zona horaria del Pacífico
  "America/Tijuana", // México - Zona horaria del Pacífico
  "America/Bahia_Banderas", // México - Zona horaria central
  "America/Bogota", // Colombia
];

const daysMapReverse = {
  0: "Domingo",
  1: "Lunes",
  2: "Martes",
  3: "Miércoles",
  4: "Jueves",
  5: "Viernes",
  6: "Sábado",
  7: "Domingo",
};

const daysMapRangeReverse = {
  "1-5": { startDay: "Lunes", endDay: "Viernes" },
  "0-6": { startDay: "Domingo", endDay: "Sábado" },
  "1-6": { startDay: "Lunes", endDay: "Sábado" },
  "1-7": { startDay: "Lunes", endDay: "Domingo" },
  "0-7": { startDay: "Domingo", endDay: "Sábado" },
  "*": { startDay: "Lunes", endDay: "Domingo" },
};

const parseCronSegment = (cronSegment) => {
  const [minutes, hours, , , daysOfWeek] = cronSegment?.split(" ") || [];
  return { minutes, hours, daysOfWeek };
};

const getDayRange = (daysOfWeek) => {
  if (daysOfWeek?.includes("-")) {
    return (
      daysMapRangeReverse[daysOfWeek] || {
        startDay: daysMapReverse[daysOfWeek.split("-")[0]],
        endDay: daysMapReverse[daysOfWeek.split("-")[1]],
      }
    );
  } else if (daysOfWeek?.includes(",")) {
    const days = daysOfWeek.split(",").map((day) => daysMapReverse[day]);
    return { startDay: days[0], endDay: days[days.length - 1] };
  } else {
    if (daysOfWeek === "*") return { startDay: "lunes", endDay: "domingo" };
    const day = daysMapReverse[daysOfWeek];
    return { startDay: day, endDay: day };
  }
};

const getTimeRange = (hours, minutes) => {
  const startMinute = minutes === "*" ? 0 : Number(minutes?.split("-")[0]);
  const currentEndMinut = Number(minutes?.split("-")[1]);
  const endMinute =
    minutes === "*" || currentEndMinut == 59
      ? "00"
      : currentEndMinut == 29
      ? 30
      : minutes;
  const startHour = Number(hours?.split("-")[0]);
  let endHour = Number(
    hours?.split("-")[1] ||
      (currentEndMinut == 59 || currentEndMinut == 29 ? hours : ++hours)
  );

  if (currentEndMinut == 59) ++endHour;

  const formatTime = (hour, minute) =>
    `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}`;

  const startTime = formatTime(startHour, startMinute);
  let endTime = formatTime(endHour, endMinute);
  return { startTime, endTime };
};

const mergeSegments = (segments) => {
  if (segments.length === 1) return segments;

  const merged = [];

  segments.forEach((segment) => {
    const last = merged[merged.length - 1];

    if (
      last &&
      last.startDay === segment.startDay &&
      last.endDay === segment.endDay &&
      last.endTime === segment.startTime
    ) {
      last.endTime = segment.endTime;
    } else {
      merged.push(segment);
    }
  });

  return merged;
};

export const cronToSchedule = (cronExpression) => {
  const segments = cronExpression.split(",");
  const scheduleParts = segments.map((segment) => {
    const { minutes, hours, daysOfWeek } = parseCronSegment(segment.trim());
    const { startDay, endDay } = getDayRange(daysOfWeek);
    const { startTime, endTime } = getTimeRange(hours, minutes);

    return { startDay, endDay, startTime, endTime };
  });

  return mergeSegments(scheduleParts);
};

export function isValidMACAddress(mac) {
  // Expresión regular para validar la dirección MAC con separadores de guiones
  const macRegex = /^([0-9A-Fa-f]{2}-){5}[0-9A-Fa-f]{2}$/;

  // Testea la dirección MAC contra la expresión regular
  return macRegex.test(mac);
}

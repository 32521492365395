import { ListItemText } from "@mui/material";

export function UserSection({ client, email }) {
  return (
    <ListItemText
      primary={
        <span style={{ fontSize: "14px" }} className="body1">
          {client}
        </span>
      }
      secondary={
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
          }}
        >
          <span style={{ fontSize: "14px" }} className="body2">
            {email}
          </span>
        </span>
      }
    />
  );
}

import { useState } from "react";

import { Menu, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Profile } from "./Profile";
import { ProfileMenu } from "./ProfileMenu";
import styles from "./styles";

export function ProfileSection() {
  const [anchorElUser, setAnchorElUser] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box
        onClick={handleOpenUserMenu}
        aria-label="account of current user"
        aria-controls="menu-profile"
        aria-haspopup="true"
      >
        <Profile />
      </Box>
      <Menu
        sx={{ mt: isMobile ? "45px" : 0 }}
        id="menu-profile"
        anchorEl={anchorElUser}
        PaperProps={styles.profileMenu}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <ProfileMenu handleClose={handleCloseUserMenu} />
      </Menu>
    </Box>
  );
}

import { useTheme } from "@mui/styles";
import styles from "./styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export function Logo() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div
      data-cy="nav-home"
      style={styles.root}
      onClick={() => {
        //hashHistory.push("/home");
      }}
    >
      {isMobile ? (
        <img src="/images/logo.svg" alt="logo" style={styles.logo} />
      ) : (
        <img
          src="/images/logo_nombre.jpg"
          alt="logo"
          style={styles.logoNombre}
        />
      )}
    </div>
  );
}

import React, { useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { Link } from "react-router-dom";
import { useState } from "react";
import { Box } from "@mui/material";
import { ConfigContext } from "Context/ConfigProvider";
import { isAvailableForPlan } from "utils/utils";
import { MENU_ITEMS, indexByMenu } from "./menu_items";
import { useLocation } from "react-router-dom";

export function Menu() {
  const { pathname } = useLocation();
  const [selectedIndex, setSelectedIndex] = useState(
    indexByMenu?.[pathname] || 0
  );
  const {
    appState: {
      identity: { accountData, userData },
    },
  } = useContext(ConfigContext);

  const { sensors_role } = userData || {};

  const { name } = accountData;

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        marginTop: "1em",
        width: "100%",
      }}
    >
      <h1
        style={{
          textTransform: "uppercase",
          fontSize: "1em",
          fontWeight: "500",
          marginLeft: "0.7em",
        }}
      >
        {name}
      </h1>
      <List sx={{ height: "100%" }}>
        {MENU_ITEMS.map(({ path, label, index, plan, icon }) =>
          isAvailableForPlan(sensors_role, plan) ? (
            <Link key={path} to={path} style={{ textDecoration: "none" }}>
              <ListItem
                button
                selected={selectedIndex === index}
                onClick={(event) => handleListItemClick(event, index)}
                sx={{
                  bgcolor: selectedIndex === index ? "lightblue" : "inherit",
                  borderLeft:
                    selectedIndex === index ? "2px solid #3770EF" : "none",
                }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText
                  primary={<span className="body2">{label}</span>}
                />
              </ListItem>
            </Link>
          ) : null
        )}
      </List>
    </Box>
  );
}

import { useContext, useMemo } from "react";

import { List, ListItem, ListItemText, Divider } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import styles from "./styles";
import { UserSection } from "./UserSection";
import { ConfigContext } from "Context/ConfigProvider";
import { AccountsSection } from "./AccountsSection";
import { ROLES } from "utils/constants";

export function ProfileMenu({ handleClose }) {
  const { appState } = useContext(ConfigContext);
  const { logout } = useAuth0();

  const { identity } = appState;
  const { accountData, userData } = identity;
  const { tenant } = accountData;
  const { sensors_role, accountsList = [] } = userData || {};

  const clientName = useMemo(() => {
    return (
      <h4 style={{ margin: 0, textTransform: "capitalize" }}>
        {identity?.userData?.name}
      </h4>
    );
  }, [identity]);

  const customLogout = (cleanStorage) => {
    handleClose();
    setTimeout(function () {
      setTimeout(function () {
        //clearLocalStorage();
        logout({ returnTo: "" });
      }, 1000);
    }, 1);
  };

  return (
    <>
      <List component="nav" sx={styles.listRoot}>
        <ListItem alignItems="flex-start" sx={styles.listItemRoot}>
          <UserSection
            client={clientName}
            email={identity?.userData?.email || ""}
          />
        </ListItem>
        <Divider component="li" />
        {sensors_role === ROLES.OPERATOR && (
          <AccountsSection
            tenantsList={accountsList}
            currentAccount={tenant}
            handleClose
          />
        )}
        <Divider component="li" />
        <ListItem
          button
          onClick={customLogout}
          sx={{ ...styles.listItemRoot, mt: 1 }}
        >
          <ListItemText
            style={{ color: "var(--blue-gray-5)", fontWeight: 500 }}
            primary={
              <span style={styles.logOutroot}>
                <LogoutOutlinedIcon sx={styles.newAccountIcon} />
                <span className="body1">{"Salir"}</span>
              </span>
            }
          />
        </ListItem>
      </List>
    </>
  );
}

import React from "react";

import { CONSTANTS } from "utils/constants";

import styles from "./styles";

const NotFound = () => {
  return (
    <div style={styles.root}>
      <img src="/images/logo.svg" alt="wivo" style={{ height: "10vh" }} />
      <h1 style={{ textAlign: "center", maxWidth: "40%", fontSize: "1.1em" }}>
        {CONSTANTS.PATH_NOT_FOUND}
      </h1>
    </div>
  );
};
export default NotFound;

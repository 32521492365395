import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import Menu from "@mui/icons-material/Menu";
import { IconButton, Typography, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import useMediaQuery from "@mui/material/useMediaQuery";

import styles from "./styles";
import { useContext } from "react";
import { ConfigContext } from "Context/ConfigProvider";

export function Profile() {
  const { appState } = useContext(ConfigContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const user = appState?.identity?.userData?.name || "";
  return (
    <Box sx={styles.profileRoot}>
      {!isMobile ? (
        <Box sx={{ display: "flex", columnGap: "0.5em" }}>
          <AccountCircleIcon
            fontSize="large"
            style={{ color: "#78909C", opacity: 0.5 }}
          />
          <span className="body2" style={{fontSize:"14px"}}>{user}</span>

          <IconButton sx={{ ...styles.profileArrowIcon, rotate: "270deg" }}>
            <ArrowDropDown />
          </IconButton>
        </Box>
      ) : (
        <>
          <Typography sx={styles.account} className="body2">
            {user}
          </Typography>
          <IconButton sx={styles.profileArrowIcon}>
            <ArrowDropDown />
          </IconButton>
          {/*  <IconButton sx={styles.profileMenuIcon}>
            <Menu />
          </IconButton> */}
        </>
      )}
    </Box>
  );
}

import React, { useContext } from "react";
import { WivoAppBar } from "components/AppBar/WivoAppBar"; // Asegúrate de que la ruta a AppBar es correcta
import {
  Box,
  Container,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AppLoading from "components/AppLoading/AppLoading";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import withCubejsApi from "withCubejsApi";
import { ConfigContext } from "Context/ConfigProvider";

export const uitheme = createTheme({
  palette: {
    //primary: { 500: "rgb(242, 44, 54)" },
  },
  fontFamily: "IBM Plex Sans",
});

const Layout = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { appState } = useContext(ConfigContext);

  if (process.env.NODE_ENV === "development")
    console.log("SENSORS-APP State Layout", appState);

  window.Intercom("update");
  return (
    <ThemeProvider theme={uitheme}>
      <WivoAppBar />
      <Box
        sx={{
          marginLeft: isMobile ? 0 : "200px",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
        }}
      >
        <Container style={{ height: "100%" }} maxWidth="xl">
          {children}
        </Container>
      </Box>
    </ThemeProvider>
  );
};

//export default Layout;

export default withAuthenticationRequired(withCubejsApi(Layout), {
  onRedirecting: () => <AppLoading text="Cargando..." />,
  returnTo: "/visits",
});

const styles = {
  root: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  logo: {
    width: 30,
    height: 30,
  },
  logoNombre: {
    height: 30,
  },
};

export default styles;

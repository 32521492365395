import { ListItemButton, ListItemText } from "@mui/material";

import { useLocation, useHistory } from "react-router-dom";

import styles from "./styles";

export function AccountsSection({
  tenantsList,
  currentAccount,
  handleClose,
  setOpenModal,
}) {
  const location = useLocation();
  const { pathname } = location;
  const history = useHistory();

  return (
    <>
      {tenantsList.map((account, indx) =>
        account.tenant !== currentAccount ? (
          <ListItemButton
            onClick={(event) => {
              const { origin, href } = window.location;
              const [, fullPath] = href.split(origin);
              const [userActive, hash] = fullPath.split(pathname);
              const newPath = pathname.startsWith("/admin")
                ? `../../${indx}${pathname}`
                : `../${indx}${pathname}`;
              window.history.pushState(null, "App", newPath);

              window.location.reload();
              sessionStorage.setItem("sensors-currentUser", indx);
            }}
            key={`${account.tenant}-${account.name}`}
          >
            <ListItemText
              primary={
                <span style={styles.accountStyle}>
                  <span className="body2">{account.name}</span>
                </span>
              }
            />
          </ListItemButton>
        ) : null
      )}
    </>
  );
}

import { COLUMNS } from "components/Columns/helpers";
import { createContext, useState } from "react";
import { ROLES } from "utils/constants";

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [appState, setAppState] = useState({
    identity: {
      accessToken: undefined,
      accountData: {},
      userData: { sensors_role: ROLES.ANALYST },
    },
    config: {
      timeDimensions: [
        {
          dimension: "SensorsMeasurements.measurementDate",
          dateRange: "Last 30 days",
        },
      ],
      filters: [],
      filtersList: {},
      dimensions: [],
      measures: [],
      columns: { ...COLUMNS },
      compareTo: "PM",
      compareWithCustom: [],
      renewQuery: false,
    },
  });
  return (
    <ConfigContext.Provider value={{ appState, setAppState }}>
      {children}
    </ConfigContext.Provider>
  );
};

import React from "react";

import { browserHistory } from "react-router";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  const onRedirectCallback = (appState) => {
    if (appState && appState.returnTo) {
      browserHistory.push(appState.returnTo);
    } else {
      browserHistory.push("/visits");
    }
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      scope="openid profile email"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
